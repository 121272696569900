import { Component, inject, Input, OnInit } from '@angular/core';
import { ServiceService } from '../../../../services/service/service.service';
import { Service } from '../../../../lib/interfaces/Service';
import { take } from 'rxjs';
import { DynamoImageType } from '@skillgmbh/dynamo';
import { CommonService } from '../../../../services/common/common.service';

@Component({
    selector: 'app-service-overview-item',
    templateUrl: './service-overview-item.component.html',
    styleUrls: ['./service-overview-item.component.scss'],
})
export class ServiceOverviewItemComponent implements OnInit {
    readonly DynamoImageType: typeof DynamoImageType = DynamoImageType;

    @Input() serviceUID!: string;

    service?: Service;

    protected commonService: CommonService = inject(CommonService);
    private serviceService: ServiceService = inject(ServiceService);

    ngOnInit(): void {
        this.serviceService
            .fetchByUID(this.serviceUID)
            .pipe(take(1))
            .subscribe((service: Service): void => {
                this.service = service;
            });
    }
}
