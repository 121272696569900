import {
    Component,
    ComponentRef,
    DestroyRef,
    EventEmitter,
    HostListener,
    inject,
    Input,
    OnDestroy,
    Output,
    Type,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { CommonService } from '../../services/common/common.service';
import { OrderService } from '../../services/order/order.service';
import { take, timer } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
    selector: 'app-element-overlay',
    templateUrl: './element-overlay.component.html',
    styleUrls: ['./element-overlay.component.scss'],
    animations: [
        trigger('openOverlayAnimation', [
            transition(':enter', [style({ opacity: 0 }), animate('150ms ease-in', style({ opacity: 1 }))]),
            transition(':leave', [style({ opacity: 1 }), animate('150ms ease-out', style({ opacity: 0 }))]),
        ]),
    ],
})
export class ElementOverlayComponent implements OnDestroy {
    @ViewChild('lazyLoadedComponent', { read: ViewContainerRef, static: false }) container?: ViewContainerRef;

    @Input() iconSize: string = '1.8rem';
    @Input() iconColor: string = '#000000';
    @Input() title: string = '';
    @Input() iconType: string = '';
    @Input() description: string = '';
    @Output() closeEvent: EventEmitter<void> = new EventEmitter<void>();

    private componentRef?: ComponentRef<unknown>;

    private commonService: CommonService = inject(CommonService);
    private destroyRef: DestroyRef = inject(DestroyRef);
    private orderService: OrderService = inject(OrderService);

    visible: boolean = true;

    @HostListener('document:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent): void {
        if (!this.commonService.IsPlatformBrowser()) return;

        if (event.key === 'Escape') {
            this.close();
        }
    }

    ngOnDestroy(): void {
        this.orderService.productMarkedForOptionSelectionSubject$.next(undefined);
        this.componentRef?.destroy();
    }

    setOverflowY(value: string): void {
        if (!this.commonService.IsPlatformBrowser()) return;

        document.documentElement.style.overflowY = value;
        document.body.style.overflowY = value;
    }

    loadComponent<T>(component: Type<T>, inputs?: Partial<{ [K in keyof T]: T[K] }>): void {
        if (!this.commonService.IsPlatformBrowser()) return;

        timer(0)
            .pipe(take(1), takeUntilDestroyed(this.destroyRef))
            .subscribe((): void => {
                if (!this.container) return;

                this.componentRef?.destroy();
                this.setOverflowY(this.visible ? 'hidden' : 'auto');
                this.componentRef = this.container.createComponent<T>(component);

                if (!inputs || !this.componentRef) return;

                Object.entries(inputs).forEach(([key, value]): void => {
                    if (Object.prototype.hasOwnProperty.call(inputs, key)) {
                        (this.componentRef as ComponentRef<T>).instance[key as keyof T] = value as T[keyof T];
                    }
                });
            });
    }

    close(): void {
        if (!this.commonService.IsPlatformBrowser()) return;

        this.visible = false;

        // This timeout is to ensure the :leave animation happens
        timer(150)
            .pipe(take(1), takeUntilDestroyed(this.destroyRef))
            .subscribe((): void => {
                if (this.componentRef) {
                    this.setOverflowY(this.visible ? 'hidden' : 'auto');
                    this.componentRef.destroy();
                }

                this.closeEvent.emit();
            });
    }
}
