@if (showPrompt) {
    <div class="pwa-install__view dynamic__padding--md dynamic__stretch--width">
        <span class="dynamic__title">Installiere MyMeal mit nur einem Klick</span>
        @if (isIos) {
            <span class="dynamic__text">Klicke <strong>Teilen</strong> & dann auf <strong>Zum Home-Bildschirm</strong></span>
        } @else {
            <div class="pwa-install__button dynamic__element--pointer"
                 (click)="installPWA()">
                <dynamo-icon dynamoIconType="download"
                             manualSize="6rem">
                </dynamo-icon>
                <span class="dynamic__title--sm">Jetzt installieren</span>
            </div>
        }
    </div>
}
