@if (!!customerOrder?.service) {
    <div class="customer-order-history-item__header">
        <!--    <img class="customer-order-history-item__header__logo"-->
        <!--         src="https://service-proxy.skillagentur.com/attributes/get/preview/logo/{{customerOrder.service?.id}}">-->
        <span class="customer-order-history-item__header__service-name dynamic__text--sm dynamic__text--bold">
            {{ customerOrder.service?.name }}
        </span>
        <span class="dynamic__text--xs">
            Bestellt am {{ customerOrder.creationTimestamp | date: 'dd.MM.yyyy' }} um
            {{ customerOrder.creationTimestamp | date: 'HH:MM' }}
        </span>
    </div>
}

@if (!!customerOrder) {
    <div class="customer-order-history-item__view">
        <span class="dynamic__text--xs">
            {{ customerOrder.customer.phone }}
        </span>
        <span class="dynamic__text--xs">
            {{ customerOrder.paymentType === PaymentType.ON_SPOT ? 'Barzahlung' : 'Online bezahlt' }}
        </span>
    </div>
}
