import { Component, DestroyRef, inject } from '@angular/core';
import { CustomerService } from '../../services/customer/customer.service';
import { DynamoHttpService, DynamoListMenuItem } from '@skillgmbh/dynamo';
import { take } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
    selector: 'app-customer-dashboard',
    templateUrl: './customer-dashboard.component.html',
    styleUrls: ['./customer-dashboard.component.scss'],
})
export class CustomerDashboardComponent {
    protected customerService: CustomerService = inject(CustomerService);
    protected httpService: DynamoHttpService = inject(DynamoHttpService);
    private destroyRef: DestroyRef = inject(DestroyRef);

    protected selectedDashboardMenuKey?: string = 'order-history';

    protected setSelectedDashboardMenuKey(listMenuOption: DynamoListMenuItem): void {
        this.selectedDashboardMenuKey = listMenuOption.key;
    }

    protected deleteCustomerAccount(): void {
        if (confirm('Das Benutzerkonto & alle damit verbundenen Daten werden gelöscht. Du wirst abgemeldet.')) {
            this.httpService
                .delete('service-proxy', 'customer', 'account')
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                .pipe(take(1), takeUntilDestroyed(this.destroyRef))
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                .subscribe((): void => {
                    this.customerService.logout();
                });
        }
    }
}
