import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { DynamoFormComponent, DynamoFormControl, DynamoFormControlType } from '@skillgmbh/dynamo';
import { FormControl, FormGroup } from '@ngneat/reactive-forms';
import { CommonService } from '../../../services/common/common.service';
import { CustomerService } from '../../../services/customer/customer.service';
import { Validators } from '@angular/forms';
import { Address } from '../../../lib/interfaces/Address';
import { CustomerAccount } from '../../../lib/interfaces/CustomerAccount';

@Component({
    selector: 'app-management-settings',
    templateUrl: './management-settings.component.html',
    styleUrls: ['./management-settings.component.scss'],
})
export class ManagementSettingsComponent implements AfterViewInit {
    @ViewChild('settingsForm', { static: false }) settingsForm: DynamoFormComponent = {} as DynamoFormComponent;
    @ViewChild('addressForm', { static: false }) addressForm: DynamoFormComponent = {} as DynamoFormComponent;
    @ViewChild('passwordForm', { static: false }) passwordForm: DynamoFormComponent = {} as DynamoFormComponent;

    settingsFormModel: DynamoFormControl[] = [
        {
            name: 'firstName',
            control: new FormControl(''),
            formControlType: DynamoFormControlType.INPUT,
            labelText: 'First name',
        },
        {
            name: 'lastName',
            control: new FormControl(''),
            formControlType: DynamoFormControlType.INPUT,
            labelText: 'Last name',
        },
        {
            name: 'phone',
            control: new FormControl(''),
            formControlType: DynamoFormControlType.INPUT,
            labelText: 'Phone nr.',
        },
        {
            name: 'email',
            control: new FormControl(''),
            formControlType: DynamoFormControlType.INPUT,
            labelText: 'Email',
        },
        {
            name: 'paymentType',
            control: new FormControl(''),
            formControlType: DynamoFormControlType.SELECT,
            selectOptions: [
                {
                    key: 'ON_SPOT',
                    text: 'On spot',
                    active: true,
                },
                {
                    key: 'PAYPAL',
                    text: 'PayPal',
                    active: false,
                },
                {
                    key: 'STRIPE',
                    text: 'Kredit/Debit Karte',
                    active: false,
                },
            ],
            labelText: 'Preferred payment',
        },
    ];

    addressFormModel: DynamoFormControl[] = [
        {
            name: 'land',
            control: new FormControl('', [Validators.required]),
            formControlType: DynamoFormControlType.INPUT,
            labelText: 'Land',
        },
        {
            name: 'city',
            control: new FormControl('', [Validators.required]),
            formControlType: DynamoFormControlType.INPUT,
            labelText: 'City',
        },
        {
            name: 'postalCode',
            control: new FormControl('', [Validators.required]),
            formControlType: DynamoFormControlType.INPUT,
            labelText: 'Postal Code',
        },
        {
            name: 'street',
            control: new FormControl('', [Validators.required]),
            formControlType: DynamoFormControlType.INPUT,
            labelText: 'Street',
        },
        {
            name: 'houseNumber',
            control: new FormControl('', [Validators.required]),
            formControlType: DynamoFormControlType.INPUT,
            labelText: 'House Number',
        },
        {
            name: 'extra',
            control: new FormControl(''),
            formControlType: DynamoFormControlType.INPUT,
            labelText: 'Extras',
        },
    ];

    passwordFormModal: DynamoFormControl[] = [
        {
            name: 'oldPassword',
            control: new FormControl('', [Validators.required]),
            formControlType: DynamoFormControlType.INPUT,
            inputType: 'password',
            labelText: 'Old password',
        },
        {
            name: 'newPassword',
            control: new FormControl('', [Validators.required]),
            formControlType: DynamoFormControlType.INPUT,
            inputType: 'password',
            labelText: 'New password',
        },
        {
            name: 'newPasswordRepeat',
            control: new FormControl('', [Validators.required]),
            formControlType: DynamoFormControlType.INPUT,
            inputType: 'password',
            labelText: 'New password repeat',
        },
    ];

    constructor(
        private commonService: CommonService,
        public customerService: CustomerService,
    ) {}

    ngAfterViewInit(): void {
        this.settingsForm?.submitEvent?.subscribe((formGroup: FormGroup<any>): void => {
            this.updateSettings(formGroup);
        });
        this.addressForm?.submitEvent?.subscribe((formGroup: FormGroup<any>): void => {
            this.addAddress(formGroup);
        });
        this.passwordForm?.submitEvent?.subscribe((formGroup: FormGroup<any>): void => {
            this.changePassword(formGroup);
        });
        this.setAccountData();
    }

    updateSettings(formGroup: FormGroup<any>): void {
        this.customerService
            .updateAccount(this.buildCustomerObject(formGroup))
            .subscribe((customerAccount: CustomerAccount): void => {
                this.customerService.customer = customerAccount;
            });
    }

    addAddress(formGroup: FormGroup<any>): void {
        this.customerService
            .addAddress(this.buildAddressObject(formGroup))
            .subscribe((customerAccount: CustomerAccount): void => {
                this.customerService.customer = customerAccount;
            });
    }

    changePassword(formGroup: FormGroup<any>): void {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (formGroup?.controls['newPassword']?.value !== formGroup?.controls['newPasswordRepeat']?.value) return;
        this.customerService
            .changePassword({
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                newPassword: formGroup?.controls['newPassword']?.value as string,
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                oldPassword: formGroup?.controls['oldPassword']?.value as string,
            })
            .subscribe();
    }

    deleteAddress(address: Address): void {
        this.customerService.removeAddress(address).subscribe((customerAccount: CustomerAccount): void => {
            this.customerService.customer = customerAccount;
        });
    }

    buildCustomerObject(formGroup?: FormGroup<any>): CustomerAccount {
        return {
            customer: {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                firstName: formGroup?.controls['firstName']?.value as string,
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                lastName: formGroup?.controls['lastName']?.value as string,
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                phone: formGroup?.controls['phone']?.value as string,
            },
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            email: formGroup?.controls['email']?.value as string,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
            paymentType: formGroup?.controls['paymentType']?.value,
        } as CustomerAccount;
    }

    buildAddressObject(formGroup?: FormGroup<any>): Address {
        return {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            country: formGroup?.controls['country']?.value as string,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            city: formGroup?.controls['city']?.value as string,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            postcode: formGroup?.controls['postalCode']?.value as string,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            street: formGroup?.controls['street']?.value as string,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
            extra: formGroup?.controls['extra']?.value,
        } as Address;
    }

    deleteAccount(): void {
        this.customerService.disableAccount().subscribe((): void => {
            this.commonService.navigate('/overview');
            this.customerService.logout();
        });
    }

    private setAccountData(): void {
        this.customerService.getByAuthentication().subscribe((customerAccount: CustomerAccount): void => {
            if (!customerAccount) return;
            this.customerService.customer = customerAccount;
            this.settingsForm.getFormControlByKey('firstName').setValue(customerAccount.customer.firstName);
            this.settingsForm.getFormControlByKey('lastName').setValue(customerAccount.customer.lastName);
            this.settingsForm.getFormControlByKey('phone').setValue(customerAccount.customer.phone);
            this.settingsForm.getFormControlByKey('email').setValue(customerAccount.email);
            this.settingsForm.getFormControlByKey('paymentType').setValue(customerAccount.paymentType);
        });
    }
}
