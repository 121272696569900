import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-pwa-install',
    templateUrl: './pwa-install.component.html',
    styleUrls: ['./pwa-install.component.scss'],
})
export class PwaInstallComponent implements OnInit {
    deferredPrompt: any;
    showPrompt: boolean = false;
    isIos: boolean = false;

    ngOnInit(): void {
        // Detect iOS devices
        this.isIos = /iphone|ipad|ipod/.test(navigator.userAgent.toLowerCase());

        // Listen for the `beforeinstallprompt` event on supported browsers
        if (!this.isIos) {
            window.addEventListener('beforeinstallprompt', (e: Event) => {
                e.preventDefault(); // Prevent the default browser prompt
                this.deferredPrompt = e; // Save the event for later use
                this.showPrompt = true; // Show the custom banner
            });
        } else {
            // Show instructions for iOS
            this.showPrompt = true;
        }
    }

    installPWA(): void {
        if (this.deferredPrompt) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            this.deferredPrompt.prompt(); // Show the install prompt
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            this.deferredPrompt.userChoice.then((choiceResult: any) => {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                if (choiceResult.outcome === 'accepted') {
                    console.log('User accepted the install prompt');
                } else {
                    console.log('User dismissed the install prompt');
                }
                this.deferredPrompt = null; // Clear the saved prompt
                this.showPrompt = false; // Hide the custom banner
            });
        }
    }
}
