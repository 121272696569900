import { ChangeDetectorRef, Component, EventEmitter, inject, Input, OnChanges, OnInit, Output } from '@angular/core';
import { DynamoFormControl, DynamoFormControlType, DynamoListMenuItem } from '@skillgmbh/dynamo';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { CommonService } from '../../services/common/common.service';
import { CustomerInfoFormData } from '../customer-info/customer-info.component';

export interface ExtendedDynamoFormControl extends DynamoFormControl {
    // this boolean specifies if a field will stretch on the full width of the contact form on desktop
    full?: boolean;
}

export interface FormResults extends DynamoFormControl {
    selectedMenuOption: DynamoListMenuItem;
    formGroupData: CustomerInfoFormData;
}

@Component({
    selector: 'app-dynamic-form',
    templateUrl: './dynamic-form.component.html',
    styleUrls: ['./dynamic-form.component.scss'],
})
export class DynamicFormComponent implements OnInit, OnChanges {
    @Input() textAreaControl?: DynamoFormControl;
    @Input() formControls: ExtendedDynamoFormControl[] = [];
    @Input() listMenuOptions: DynamoListMenuItem[] = [];
    @Input() submitButtonText: string = 'Submit';
    @Output() successSubmit: EventEmitter<FormResults> = new EventEmitter<FormResults>();

    protected selectedMenuOption?: DynamoListMenuItem;

    formGroup: UntypedFormGroup = new UntypedFormGroup({});

    changeDetectorRef: ChangeDetectorRef = inject(ChangeDetectorRef);
    commonService: CommonService = inject(CommonService);

    ngOnInit(): void {
        this.init();
    }

    ngOnChanges(): void {
        this.init();
    }

    init(): void {
        this.formControls.forEach((formConfig: DynamoFormControl): void => {
            this.formGroup.addControl(formConfig.name, formConfig.control);
        });
        if (this.textAreaControl !== undefined) {
            this.formGroup.addControl(this.textAreaControl.name, this.textAreaControl.control);
        }
        this.selectedMenuOption = this.listMenuOptions[0];
        this.changeDetectorRef.detectChanges();
    }

    onFormSubmit(): void {
        Object.keys(this.formGroup.controls).forEach((key: string): void => {
            this.formGroup.get(key)?.markAsDirty();
        });

        if (this.formGroup.invalid) return;

        this.successSubmit.emit({
            formGroupData: this.getGroupValue(),
            selectedMenuOption: this.selectedMenuOption,
        } as FormResults);
    }

    getGroupValue(): CustomerInfoFormData {
        return this.formGroup.value as CustomerInfoFormData;
    }

    isInput(control: DynamoFormControl): boolean {
        return control.formControlType === DynamoFormControlType.INPUT;
    }

    adjustTextAreaHeight(event: Event): void {
        const textarea: HTMLTextAreaElement = event.target as HTMLTextAreaElement;

        const lines = textarea.value.split('; ');
        const lastLine = lines[lines.length - 1]?.trim();

        textarea.value = textarea.value.replace(/\n+/g, lastLine ? '; ' : '');

        const paddingTop = parseFloat(getComputedStyle(textarea).paddingTop);
        const paddingBottom = parseFloat(getComputedStyle(textarea).paddingBottom);
        const totalPadding = paddingTop + paddingBottom;

        if (textarea.scrollHeight + totalPadding > textarea.offsetHeight) {
            textarea.style.height = `${textarea.scrollHeight - totalPadding}px`;
        }
    }

    getErrorMessage(control: AbstractControl<unknown>, isCheckbox = false): string {
        if (control.hasError('required')) {
            if (isCheckbox) {
                return 'Bitte Zustimmung erteilen';
            }
            return 'Bitte ausfüllen';
        }

        if (control.hasError('minlength')) {
            return 'Bitte ausfüllen';
        }

        if (control.hasError('email')) {
            return 'Bitte geben Sie eine gültige E-Mail Adresse an, wie z.B.: info@mymeal.at';
        }

        if (control.hasError('pattern')) {
            return 'Bitte geben Sie eine gültige Telefonnummer an, wie z.B.: 00 43 123 456789';
        }

        return '';
    }
}
