import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    standalone: true,
    name: 'KeyOfEnumPipe',
})
export class KeyOfEnumPipePipe implements PipeTransform {
    transform<T>(value: string, enumType: T): string {
        const key: string = enumType[value as keyof T] as string;

        if (!key) return value;

        return key;
    }
}
