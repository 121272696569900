<div class="service-inline-info__view"
    [style.gap]="gap">
    <dynamo-icon-and-text iconType="restaurant"
                          layout="row"
                          layoutGap=".4rem"
                          iconColor="black"
                          textColor="black"
                          iconSize="1.5rem"
                          textSize="12px"
                          text="Vorbestellung">
    </dynamo-icon-and-text>
    <dynamo-icon-and-text iconType="schedule"
                          layout="row"
                          layoutGap=".4rem"
                          iconColor="black"
                          textColor="black"
                          iconSize="1.5rem"
                          textSize="12px"
                          text="00:00 - 11:00">
    </dynamo-icon-and-text>
    <dynamo-icon-and-text iconType="directions_walk"
                          layout="row"
                          layoutGap=".4rem"
                          iconColor="black"
                          textColor="black"
                          iconSize="1.5rem"
                          textSize="12px"
                          text="Abholung">
    </dynamo-icon-and-text>
</div>
