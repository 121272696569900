<div class="customer-order-history__view">
    <div class="customer-order-history__history dynamic__stretch--width">
        @for (customerOrder of customerOrders; track customerOrder) {
            <app-customer-order-history-item
                class="customer-order-history__history__item dynamic__stretch--width"
                [customerOrder]="customerOrder"
                (click)="openOrderOverview(customerOrder)">
            </app-customer-order-history-item>
        }
    </div>
</div>
