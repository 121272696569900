@if(serviceUIDs.length) {
    <div class="service-overview__view dynamic__padding--lg">
        <span
            class="service-overview__title dynamic__title--sm dynamic__text--bold dynamic__text--center">Bestelle bei {{ serviceUIDs.length }}
            Restaurants</span>
        <div class="service-overview__list">
            @for(serviceUID of serviceUIDs; track serviceUID) {
                <app-service-overview-item routerLink="/{{serviceUID}}"
                                           [serviceUID]="serviceUID">
                </app-service-overview-item>
            }
        </div>
        <span class="dynamic__text--xs dynamic__text--center">Dein Lieblingsrestaurant ist nicht dabei?<br><a
            class="dynamic__text--link">Schlag es uns gerne vor.</a></span>
    </div>
}
