<div class="service-info__view">
    @if(!!service){
        <span class="dynamic__text">{{ service.name }}</span>
        @if (!!service.additionalServiceAttributes) {
            <span class="dynamic__text--sm">{{ service.additionalServiceAttributes.street }} {{ service.additionalServiceAttributes.houseNumber }}</span>
            <span class="dynamic__text--sm">{{ service.additionalServiceAttributes.postcode }} {{ service.additionalServiceAttributes.city }}</span>
            <span class="dynamic__text--sm">{{ service.additionalServiceAttributes.country }}</span>
        }
    }
</div>
