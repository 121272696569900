<span class="form__container"
      fxLayout="column"
      fxLayoutAlign="start start"
      fxLayoutGap="2rem">
      <ng-container (keydown.enter)="updateSettings(settingsForm.formGroup)">
        <span style="max-width: 800px; width: 100%; min-width: 300px;">
            <dynamo-form
              #settingsForm
              formTitle="Main Settings"
              submitButtonText="Update"
              [data]="settingsFormModel">
            </dynamo-form>
        </span>
        </ng-container>
      <mat-divider [vertical]="false"
                   style="color: black; margin-right: 1rem; width: 100%; padding-left: .5rem !important;"></mat-divider>
      <ng-container (keydown.enter)="addAddress(addressForm.formGroup)">
        <span style="max-width: 800px; width: 100%; min-width: 300px;">
            <dynamo-form
              #addressForm
              formTitle="Addresses"
              submitButtonText="Add address"
              [data]="addressFormModel">
            </dynamo-form>
        </span>
      </ng-container>
      <span fxLayout="row"
            fxLayoutAlign="center center"
            fxLayoutGap="2rem"
            *ngFor="let address of this.customerService.customer?.addresses; index as i">
          {{address.country}},
          {{address.city}}
          {{address.postcode}},
          {{address.street}}
          {{address.extra}}
        <span fxLayoutAlign="center center"
              style="color: crimson; cursor: pointer;"
              (click)="deleteAddress(address)">
          <mat-icon> delete </mat-icon>
        </span>
      </span>
      <mat-divider [vertical]="false"
               style="color: black; margin-right: 1rem; width: 100%; padding-left: .5rem !important;"></mat-divider>
      <ng-container (keydown.enter)="changePassword(passwordForm.formGroup)">
        <span style="max-width: 800px; width: 100%; min-width: 300px;">
            <dynamo-form
              #passwordForm
              formTitle="Security"
              submitButtonText="Change password"
              [data]="passwordFormModal">
            </dynamo-form>
        </span>
      </ng-container>
      <mat-divider [vertical]="false"
               style="color: black; margin-right: 1rem; width: 100%; padding-left: .5rem !important;"></mat-divider>
      <span
        fxLayoutAlign="end center"
        style="width: 100%;">
        <span class="dynamo__dynamic__form__field__submit"
              fxLayoutAlign="center center"
              fxLayoutGap="0.5rem"
              style="color: crimson !important; padding: 0.5rem; cursor: pointer;"
              (click)="deleteAccount()">
          <mat-icon> delete </mat-icon>
          <span> Delete account </span>
        </span>
      </span>
</span>



