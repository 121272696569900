<div class="schedules__view">
    @for (formattedSchedule of formattedSchedules; track formattedSchedule) {
        <div class="schedules__schedule">
            <span class="dynamic__text dynamic__text--bold">{{ formattedSchedule.day }}</span>
            @for (schedule of formattedSchedule.schedules; track schedule) {
                <span class="dynamic__text--sm">{{ schedule.startTime }} - {{ schedule.endTime }}</span>
            }
        </div>
    }
</div>
