import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent, ArraySortPipe } from './app.component';
import { ElementHeaderComponent } from './elements/element-header/element-header.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DynamoIconComponent, DynamoListMenuComponent, DynamoModule } from '@skillgmbh/dynamo';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { OrderByPipe, OrderComponent } from './components/order/order.component';
import { CheckoutComponent } from './components/checkout/checkout.component';
import { CustomerInfoComponent } from './components/customer-info/customer-info.component';
import { OrderConfirmComponent } from './components/order-confirm/order-confirm.component';
import { ImpressumComponent } from './components/impressum/impressum.component';
import { IGoogleAnalyticsCommand, NgxGoogleAnalyticsModule } from 'ngx-google-analytics';
import { MatSelectModule } from '@angular/material/select';
import { OrderEventComponent } from './components/order-event/order-event.component';
import { ServiceOverviewComponent } from './components/service-overview/service-overview.component';
import { ElementFooterComponent } from './elements/element-footer/element-footer.component';
import { MatRadioModule } from '@angular/material/radio';
import { ElementOrderHeaderComponent } from './elements/element-order-header/element-order-header.component';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { ManagementComponent } from './components/management/management.component';
import { ManagementElementHeaderComponent } from './elements/management-element-header/management-element-header.component';
import { CustomerOrderHistoryComponent } from './components/customer-order-history/customer-order-history.component';
import { ManagementSettingsComponent } from './components/management/management-settings/management-settings.component';
import { MatTableModule } from '@angular/material/table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OrderProductSelectOptionsComponent } from './components/order-product-select-options/order-product-select-options.component';
import { ServiceOverviewItemComponent } from './components/service-overview/components/service-overview-item/service-overview-item.component';
import { ResponsiveVisibilityDirective } from './directives/responsive-visibility.directive';
import { ServiceInlineInfoComponent } from './components/service-inline-info/service-inline-info.component';
import { ElementOverlayComponent } from './elements/element-overlay/element-overlay.component';
import { ElementOverlayBaseComponent } from './elements/element-overlay-base/element-overlay-base.component';
import { CustomerDashboardComponent } from './components/customer-dashboard/customer-dashboard.component';
import { CustomerOrderHistoryItemComponent } from './components/customer-order-history-item/customer-order-history-item.component';
import { DynamicFormComponent } from './components/dynamic-form/dynamic-form.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { SchedulesComponent } from './components/order-time-entry/schedules.component';
import { PwaInstallComponent } from './components/pwa-install/pwa-install.component';
import { CustomerOrderDetailsComponent } from './components/customer-order-details/customer-order-details.component';

@NgModule({
    declarations: [
        AppComponent,
        ElementHeaderComponent,
        OrderComponent,
        CheckoutComponent,
        OrderProductSelectOptionsComponent,
        CustomerInfoComponent,
        OrderConfirmComponent,
        ImpressumComponent,
        ArraySortPipe,
        OrderEventComponent,
        ServiceOverviewComponent,
        ElementFooterComponent,
        ElementOrderHeaderComponent,
        LoginComponent,
        RegisterComponent,
        ManagementComponent,
        ManagementElementHeaderComponent,
        CustomerOrderHistoryComponent,
        ManagementSettingsComponent,
        ServiceOverviewItemComponent,
        ServiceInlineInfoComponent,
        ElementOverlayComponent,
        ElementOverlayBaseComponent,
        CustomerDashboardComponent,
        CustomerOrderHistoryItemComponent,
        DynamicFormComponent,
        PwaInstallComponent,
    ],
    exports: [LoginComponent, RegisterComponent],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        environment.production ? ServiceWorkerModule.register('ngsw-worker.js', { enabled: true }) : [],
        NgxGoogleAnalyticsModule.forRoot('G-477LZX61XY', [
            {
                command: 'config',
                values: ['G-477LZX61XY', { storage: 'none', client_id: null }],
            } as IGoogleAnalyticsCommand,
        ]),
        AppRoutingModule,
        FlexLayoutModule,
        MatMenuModule,
        MatIconModule,
        MatListModule,
        MatButtonModule,
        BrowserAnimationsModule,
        DragDropModule,
        DynamoModule,
        MatSelectModule,
        FormsModule,
        MatRadioModule,
        MatTableModule,
        ResponsiveVisibilityDirective,
        ReactiveFormsModule,
        DynamoListMenuComponent,
        DynamoIconComponent,
        SchedulesComponent,
        OrderByPipe,
        CustomerOrderDetailsComponent,
    ],
    providers: [provideHttpClient(withInterceptorsFromDi())],
})
export class AppModule {}
