<div class="element-header__header">
        <span class="dynamic__text--xs dynamic__element--pointer"
              showWhen="above"
              [screenWidthTriggerPx]="550"
              responsiveVisibility
              (click)="openOverlay()">
            Jetzt anmelden & bisherige Bestellungen einsehen.
        </span>
    <div class="element-header__view">
        <dynamo-icon class="element-header__button"
                     dynamoIconType="back"
                     manualSize="1rem"
                     [routerLink]="fetchBackNavigationRoute()"
                     *ngIf="!commonService.isRouteActive('bestellen')">
        </dynamo-icon>
        <img class="element-header__branding--logo" src="assets/logos/mymeal/mymeal-logo-full.svg">

        <span class="dynamic__divider--vertical"
              *ngIf="!!orderService.order.service">
        </span>

        <div *ngIf="!!orderService.order.service"
             showWhen="above"
             [screenWidthTriggerPx]="730"
             responsiveVisibility>
            <div class="element-header__service-info">
                <img class="element-header__service-info__logo"
                     src="{{environment.service_proxy}}attributes/get/preview/logo/{{orderService.order.service.id}}">
                <span class="dynamic__text--xs dynamic__text--bold">
        {{ orderService.order.service.name }}
    </span>
            </div>
        </div>

        <span class="dynamic__divider--vertical"
              showWhen="above"
              responsiveVisibility>
        </span>

        <div class="element-header__account dynamic__element--pointer"
             (click)="openOverlay()">
            <span class="dynamic__text--xs"
                  [screenWidthTriggerPx]="450"
                  showWhen="above"
                  responsiveVisibility>
            {{ !!customerAccount ? customerAccount.customer.firstName + ' ' + customerAccount.customer.lastName : 'Anmelden' }}
        </span>
            <ng-container *ngIf="!!customerAccount || commonService.isMobile(450)">
                <span class="dynamic__text--xxs"
                      [screenWidthTriggerPx]="450"
                      showWhen="above"
                      responsiveVisibility>
                    Bestellungen & Konto
                </span>
                <dynamo-icon class="element-header__links__icon dynamic__element--pointer"
                             showWhen="below"
                             dynamoIconType="account"
                             manualSize="1.5rem"
                             [screenWidthTriggerPx]="450"
                             responsiveVisibility>
                </dynamo-icon>
            </ng-container>
        </div>

        <span class="dynamic__divider--vertical"></span>

        <div class="element-header__links">
            <dynamo-icon class="element-header__links__icon dynamic__element--pointer" dynamoIconType="impressum"
                         manualSize="1.5rem"
                         routerLink="/impressum">
            </dynamo-icon>
            <dynamo-icon class="element-header__links__icon dynamic__element--pointer" dynamoIconType="support"
                         manualSize="1.5rem"
                         routerLink="/impressum">
            </dynamo-icon>
        </div>
    </div>
</div>
