import { Component, Input } from '@angular/core';
import { Order } from '../../lib/interfaces/Order';
import { PaymentType } from '../../lib/enums/PaymentType';

@Component({
    selector: 'app-customer-order-history-item',
    templateUrl: './customer-order-history-item.component.html',
    styleUrls: ['./customer-order-history-item.component.scss'],
})
export class CustomerOrderHistoryItemComponent {
    readonly PaymentType: typeof PaymentType = PaymentType;

    @Input() customerOrder!: Order;
    @Input() compact: boolean = false;
}
