import { Component, inject, Pipe, PipeTransform } from '@angular/core';
import { DynamoHttpInstance, DynamoHttpService, DynamoIcon, DynamoIconService } from '@skillgmbh/dynamo';
import { environment } from '../environments/environment';
import { Router } from '@angular/router';

@Pipe({
    name: 'sort',
})
export class ArraySortPipe implements PipeTransform {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    transform(array: any, field: string, direction: 'asc' | 'desc'): any[] {
        if (!Array.isArray(array)) {
            return [];
        }
        if (direction.includes('desc')) {
            array.sort((a: any, b: any) => {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                if (a[field] > b[field]) {
                    return -1;
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                } else if (a[field] < b[field]) {
                    return 1;
                } else {
                    return 0;
                }
            });
            return array;
        }
        array.sort((a: any, b: any) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            if (a[field] < b[field]) {
                return -1;
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            } else if (a[field] > b[field]) {
                return 1;
            } else {
                return 0;
            }
        });
        return array;
    }
}

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {
    private httpService: DynamoHttpService = inject(DynamoHttpService);
    private iconService: DynamoIconService = inject(DynamoIconService);
    private router: Router = inject(Router);

    constructor() {
        this.httpService.registerInstance('service-proxy', {
            url: environment.service_proxy,
            authorizationEnabled: true,
            storageTokenKey: 'auth_tkn',
        } as DynamoHttpInstance);

        this.registerDynamoIcon('back', 'assets/icons/dynamo/back.png');
        this.registerDynamoIcon('impressum', 'assets/icons/dynamo/impressum.svg');
        this.registerDynamoIcon('support', 'assets/icons/dynamo/support.svg');
        this.registerDynamoIcon('account', 'assets/icons/dynamo/account.svg');
        this.registerDynamoIcon('select', 'assets/icons/dynamo/select.svg');
        this.registerDynamoIcon('takeaway', 'assets/icons/dynamo/takeaway.svg');
        this.registerDynamoIcon('on_site', 'assets/icons/dynamo/on_site.svg');
        this.registerDynamoIcon('shopping-bag', 'assets/icons/dynamo/shopping-bag.svg');
        this.registerDynamoIcon('order', 'assets/icons/dynamo/order.svg');
    }

    registerDynamoIcon(type: string, source: string): void {
        this.iconService.registerDynamoIcon({
            type: type,
            source: source,
        } as DynamoIcon);
    }
}
