import { Component, ElementRef, inject, ViewChild } from '@angular/core';
import { OrderService } from '../../services/order/order.service';
import { Router } from '@angular/router';
import { Order } from '../../lib/interfaces/Order';
import { CommonService } from '../../services/common/common.service';

@Component({
    selector: 'app-order-confirm',
    templateUrl: './order-confirm.component.html',
    styleUrls: ['./order-confirm.component.scss'],
})
export class OrderConfirmComponent {
    @ViewChild('printSection', { read: ElementRef }) printSection!: ElementRef;

    protected order?: Order;

    private commonService: CommonService = inject(CommonService);
    private orderService: OrderService = inject(OrderService);
    private router: Router = inject(Router);

    constructor() {
        if (!this.orderService.order.orderProducts) {
            void this.router.navigateByUrl('/bestellen');
        }

        this.order = this.orderService.order;
        this.orderService.order = {} as Order;
    }

    protected printOrderDetails(): void {
        if (!this.commonService.IsPlatformBrowser()) return;

        window.print();
    }
}
