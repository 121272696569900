@if(serviceUIDs.length) {
    <div class="service-overview__view dynamic__padding--lg">
        <span
            style="line-height: 110%;"
            class="dynamic__title--sm dynamic__text--bold dynamic__text--center">Bestelle bei {{ serviceUIDs.length }}
            Restaurants</span>
        <div class="service-overview__list">
            <app-service-overview-item routerLink="/{{serviceUID}}"
                                       [serviceUID]="serviceUID"
                                       *ngFor="let serviceUID of serviceUIDs">
            </app-service-overview-item>
        </div>
        <span class="dynamic__text--xs dynamic__text--center">Dein Lieblingsrestaurant ist nicht dabei?<br><a
            class="dynamic__text--link">Schlag es uns gerne vor.</a></span>
    </div>
}
