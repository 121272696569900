import { Component, inject, OnInit, ViewChild } from '@angular/core';
import { DynamoFormComponent, DynamoFormControl, DynamoFormControlType } from '@skillgmbh/dynamo';
import { FormControl } from '@ngneat/reactive-forms';
import { AbstractControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CustomerService } from '../../services/customer/customer.service';
import { CustomerAccount } from '../../lib/interfaces/CustomerAccount';
import { OverlayService } from '../../services/overlay/overlay.service';
import { CustomerDashboardComponent } from '../customer-dashboard/customer-dashboard.component';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
    @ViewChild('registerForm') registerForm?: DynamoFormComponent;

    successfulRequest: boolean = false;
    formGroup: UntypedFormGroup;
    formModel: DynamoFormControl[] = [
        {
            name: 'firstName',
            control: new FormControl('', [Validators.required]),
            formControlType: DynamoFormControlType.INPUT,
            inputType: 'text',
            labelText: 'Vorname',
        },
        {
            name: 'lastName',
            control: new FormControl('', [Validators.required]),
            formControlType: DynamoFormControlType.INPUT,
            inputType: 'text',
            labelText: 'Nachname',
        },
        {
            name: 'phone',
            control: new FormControl('', [
                Validators.required,
                Validators.minLength(2),
                Validators.pattern(/^\+[0-9\s\-().]+$/),
            ]),
            formControlType: DynamoFormControlType.INPUT,
            labelText: 'Telefonnummer',
            inputType: 'phone',
            selectPlaceholder: '+43 676 758 40 07',
        },
        {
            name: 'password',
            control: new FormControl('', [Validators.required, Validators.minLength(6)]),
            formControlType: DynamoFormControlType.INPUT,
            labelText: 'Passwort',
            inputType: 'password',
        },
        {
            name: 'passwordConfirm',
            control: new FormControl('', [Validators.required]),
            formControlType: DynamoFormControlType.INPUT,
            labelText: 'Passwort bestätigen',
            inputType: 'password',
        },
    ];

    customerService: CustomerService = inject(CustomerService);
    overlayService: OverlayService = inject(OverlayService);

    constructor() {
        this.formGroup = new UntypedFormGroup({});
    }

    ngOnInit(): void {
        this.formModel.forEach((formConfig: DynamoFormControl): void => {
            this.formGroup.addControl(formConfig.name, formConfig.control);
        });
    }

    buildUserObject(formGroup?: UntypedFormGroup): CustomerAccount {
        return {
            password: formGroup?.controls['password']?.value as string,
            email: this.customerService.getEmail(),
            customer: {
                firstName: formGroup?.controls['firstName']?.value as string,
                lastName: formGroup?.controls['lastName']?.value as string,
                phone: formGroup?.controls['phone']?.value as string,
            },
        } as CustomerAccount;
    }

    onFormSubmit(): void {
        Object.keys(this.formGroup.controls).forEach((key: string): void => {
            this.formGroup.get(key)?.markAsDirty();
        });
        if (this.formGroup.controls['password'].value !== this.formGroup.controls['passwordConfirm'].value) {
            this.formGroup.controls['passwordConfirm'].setErrors({ comparison: 'notequal' });
        }
        if (this.formGroup.invalid) return;
        this.customerService.register(this.buildUserObject(this.formGroup)).subscribe({
            next: (customerAccount: CustomerAccount): void => {
                this.customerService.customer = customerAccount;
                this.successfulRequest = true;
                this.overlayService.updateInputs({ title: 'Das hat geklappt!', iconType: '', description: '' });
                // this.overlayService.close();
            },
            error: (): void => {
                this.formGroup.controls['email'].setErrors({ serverError: 'unauthorized' });
            },
        });
    }

    getErrorMessage(control: AbstractControl<unknown>): string {
        if (control.hasError('required')) {
            return 'Bitte ausfüllen';
        }

        if (control.hasError('minlength')) {
            return 'Ungültiges Passwort (min. 6 Zeichnen)';
        }

        if (control.hasError('email')) {
            return 'Bitte geben Sie eine gültige E-Mail Adresse an';
        }

        if (control.hasError('pattern')) {
            return 'Bitte geben Sie eine gültige Telefonnummer an, wie z.B.: +43 123 45678';
        }

        if (control.hasError('serverError')) {
            return 'Es existiert bereits ein Konto mit dieser E-Mail';
        }

        if (control.hasError('comparison')) {
            return 'Die Passwörter stimmen nicht überein';
        }

        return '';
    }

    isInput(control: DynamoFormControl): boolean {
        return control.formControlType === DynamoFormControlType.INPUT;
    }

    openAccountOverlay(): void {
        this.overlayService.updateInputs({ title: this.customerService.getFullName(), iconType: '', description: '' });
        this.overlayService.updateComponent(CustomerDashboardComponent);
    }
}
