<ng-container>
    <span fxLayout="column"
          fxLayoutAlign="start center"
          class="printSection dynamic__stretch--view dynamic__padding--md">
        <div class="order-confirm__header">
            <img class="order-confirm__image" src="assets/check-art.png">
            <span class="order-confirm__heading not-printable">Alles erledigt</span>
            <span
                class="order-confirm__title dynamic__title--md">Deine Bestellung wurde weitergeleitet<br>Bitte überprüfe deine E-Mails</span>
            @if (!!order) {
                <app-customer-order-details
                    class="order-confirm__review"
                    [customerOrder]="order">
                </app-customer-order-details>
                <div class="dynamic__stretch--width dynamic__text--center dynamic__padding--sm not-printable">
                    <button class="dynamic__button" (click)="printOrderDetails()">Drucken</button>
                </div>
            }
        </div>
    </span>
</ng-container>
