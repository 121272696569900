import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Schedule } from '../../lib/interfaces/Schedule';
import { StartEndTime } from './interfaces/StartEndTime';

interface FormattedSchedule {
    day: string;
    schedules: StartEndTime[];
}

@Component({
    selector: 'app-order-times',
    standalone: true,
    templateUrl: './schedules.component.html',
    styleUrl: './schedules.component.scss',
    imports: [],
})
export class SchedulesComponent implements OnChanges {
    @Input({ required: true }) schedules!: Schedule[];

    protected formattedSchedules: FormattedSchedule[] = [];

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['schedules']) {
            this.schedules.forEach((schedule: Schedule): void => {
                schedule.weekdays.forEach(({ day }): void => {
                    if (
                        !this.formattedSchedules.find(
                            (formattedSchedule: FormattedSchedule): boolean => formattedSchedule.day === day,
                        )
                    ) {
                        this.formattedSchedules.push({ day, schedules: [] });
                    }
                    const formattedSchedule: FormattedSchedule | undefined = this.formattedSchedules.find(
                        (formattedSchedule: FormattedSchedule): boolean => formattedSchedule.day === day,
                    );

                    formattedSchedule?.schedules.push({
                        startTime: schedule.startTime,
                        endTime: schedule.endTime,
                    });
                });
            });
        }
    }
}
