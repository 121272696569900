<ng-container *ngIf="!!service">
    <div class="service-overview-item__view">
        <div class="service-overview-item__information">
            <div class="service-overview-item__information__header">
                <span
                    class="service-overview-item__information__header__title dynamic__text dynamic__text--bold">{{ service.name }}</span>
                <span class="service-overview-item__information__description dynamic__text--xs">Traditionelle Küche, Metzgerei</span>
            </div>
                        <app-service-inline-info class="service-overview-item__information__inline"></app-service-inline-info>
        </div>

        <dynamo-image
            class="service-overview-item__image"
            [width]="commonService.isMobile() ? '100%' : '15rem'"
            height="100%"
            backgroundSize="cover"
            source="https://service-proxy.skillagentur.com/attributes/get/preview/banner/1"
            dynamoImageSource>
        </dynamo-image>
    </div>
</ng-container>
